import React, { Fragment }  from 'react';
import { List, Label }      from 'semantic-ui-react';

import MasterDetail                     from 'components/MasterDetail';
import scopedToRole                     from 'hocs/scopedToRole';
import withPagination                   from 'hocs/withPagination';
import withSearch                       from 'hocs/withSearch';
import connectResource                  from 'utils/connectResource';
import {  getPageAllById,
          getAllById,
          getById,
          getAll }  from 'utils/connectors';

import { sortByAttributes, reverseSortByAttributes }             from 'utils/sortComparators';
import { textSubstringFilter }          from 'utils/recordFilters';
import {  forms,
          fields,
          calculations,
          calculationsOrder,
          affiliations,
          cloneRecords }  from 'resources/organizationResources';

import SideCardView                     from '../SideCardView';
import DetailView                       from 'components/forms/DetailView';
import FormView                         from 'components/forms/FormView';
import FormDataModal                    from 'components/FormDataModal';

// -----------------------------------------------------
// Connected Form View
// -----------------------------------------------------

const ConnectedFormView = connectResource(FormView, {
  key: ({ match: { params: { id } } }) => id,
  connectors: {
    selected:     getById(forms, ({ match: { params: { id } } }) => id),
    affiliations: getAll(affiliations),
    fields:       getAll(fields)
  },
  mapDispatchToProps: { ...forms.actions }
});

// -----------------------------------------------------
// Connected Detail View
// -----------------------------------------------------

const { update:formUpdate } = forms.actions;
const {
  create: calculationCreate,
  update: calculationUpdate,
  destroy: calculationDestroy } = calculations.actions;
const calculationOrderUpdate = calculationsOrder.actions.update;

const detailMapDispatchToProps = {
  formUpdate,
  calculationCreate,
  calculationUpdate,
  calculationDestroy,
  calculationOrderUpdate
};

const ScopedDetailView    = scopedToRole(DetailView, { role: 'organization' });
const ConnectedDetailView = connectResource(ScopedDetailView, {
  key: ({ match: { params: { id } } }) => id,
  connectors: {
    selected:     getById(forms, ({ match: { params: { id } } }) => id),
    calculations: getAllById(
      calculations,
      ({ match: { params: { id } } }) => ({ form_id: id }),
      true
    )
  },
  mapDispatchToProps: detailMapDispatchToProps
});

const ListItem = ({ record: { name, active, form_type } }) => (
  <Fragment>
    {
      form_type === 'internal' &&
      <List.Content floated='right'>
        <Label size='mini' color='blue'>
          Internal
        </Label>
      </List.Content>
    }
    {
      !active &&
      <List.Content floated='right'>
        <Label size='mini' color='grey'>
          Inactive
        </Label>
      </List.Content>
    }
    <List.Header>{ name }</List.Header>
  </Fragment>
);

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class App extends React.Component {
  constructor(props) {
    super(props);

    this.handleCreate = this.handleCreate.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleClone  = this.handleClone.bind(this);
  }

  render() {
    const { data:{ forms=[] } } = this.props;
    const disabledStates = forms.reduce((result, { id, is_owned }) => {
      result[id] = is_owned;
      return result;
    }, {});

    return (
      <MasterDetail masterItemComponent={ListItem}
                    detailComponent={ConnectedDetailView}
                    formComponent={ConnectedFormView}
                    sideCardComponent={SideCardView}
                    cloneModalComponent={FormDataModal}
                    records={ forms }
                    onDelete={ this.handleDelete }
                    onUpdate={ this.handleUpdate }
                    onCreate={ this.handleCreate }
                    onClone={ this.handleClone }
                    groupable={ true }
                    importable={ true }
                    versioned={true}
                    resource='forms'
                    listSort={ sortByAttributes('name') }
                    secondaryListSort={ reverseSortByAttributes('active') }
                    filterRecord={textSubstringFilter('name') }
                    disableEdit={ (id) => disabledStates[id] }
                    { ...this.props } />
    );
  }

  handleCreate(record) {
    const { create } = this.props;

    return create(record);
  }

  handleUpdate(record) {
    const { update }                                              = this.props;
    const { name, description, active, redirect_url, form_fields, affiliation_ids, id } = record;

    return update({ name, description, active, redirect_url, affiliation_ids, form_fields }, { id });
  }

  handleDelete(id) {
    const { destroy } = this.props;

    return destroy(null, { id });
  }

  handleClone(id, cloneData) {
    const { clone } = this.props;
    const scope     = { cloneable_type: 'forms', cloneable_id: id, include_data: cloneData };

    // if cloning is done in background
    if (cloneData === 1) {
      alert('Copy is being created. You will receive an email when finished');
    }

    return clone(null, scope);
  }
}

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

const connectedComponent = connectResource(App, {
  key: ({ page, query }) => [page, query],
  connectors: {
    forms: getPageAllById(forms)
  },
  mapDispatchToProps: {
    ...forms.actions,
    ...cloneRecords.actions
  }
});

export default withSearch(withPagination(connectedComponent));
