import React, { Component }      from 'react';
import PropTypes                 from 'prop-types';
import { Modal, Button,
         Header, Form }          from 'semantic-ui-react';
import { windowFunctionType,
         aggFunctionType,
         functionOptions,
         aggregationOptions,
         componentOptions,
         windowFunctionOptions } from 'constants/reports/FunctionTypes';
import { flattenSelectedFields,
         deriveWindowComponent,
         deriveFilters }        from 'utils/reportDefinitions';
import { compact }               from 'lodash';
import FilterSelector            from './FilterSelector';
import Formatter                 from './Formatter';

import 'style/containers/organization/reports/wizard.css';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

function initialState(record, functionType, windowFunction) {
  const { field, type, form,
          label     = null,
          component = null,
          agg       = null,
          agg_query = null,
          filters   = null,
          formatter = null }  = record;

  const isAggFunction       = functionType === aggFunctionType;
  const isWindowFunction    = functionType === windowFunctionType;

  const windowComponent     = isWindowFunction
                              ? deriveWindowComponent( windowFunction, field.id, form, type)
                              : null;

  const initialFunctionType = ((isAggFunction && agg) ||
                              (isWindowFunction && windowComponent)) ?
                                functionType : 'none';

  const derivedFilters      = deriveFilters(form, isAggFunction, filters, windowFunction);

  return {
    label:        label,
    functionType: initialFunctionType,
    component:    component,
    agg:          agg,
    agg_query:    agg_query,
    windowComponent: windowComponent,
    isMainWindow: !windowFunction?.comparison || windowComponent === 'order_fields:compare',
    filters:      derivedFilters,
    fieldType:    field.field_type || field.calculation_subtype || field.calculation_type,
    formatter:    formatter
  };
}

function isFunctionTypeDisabled(value, reportFunctionType){
  if (value === 'none') { return false; }

  return reportFunctionType && value !== reportFunctionType;
}

function defaultColumnLabel(functionType, agg, name) {
  if (functionType === 'none') {
    return name;
  }

  return compact([agg, name]).join(' ');
}

function createWindowComponentField(formId, field, type, windowType) {
  return {
    form: formId,
    field: field.id,
    type: type,
    function: windowType ? windowType : undefined
  };
}

function shouldShowWindowOptions(isWindowFunction, isMainWindow, fieldType) {
  const isNumeric = fieldType === 'numeric' || fieldType === 'number';
  return !isWindowFunction || isMainWindow || (!isMainWindow && isNumeric);
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class EditModal extends Component {
  constructor(props) {
    super(props);

    const { index,
            selectedFields,
            functionType,
            reportDefinition } = props;
    const record               = selectedFields[index];

    this.state = initialState(record, functionType, reportDefinition.window);

    this.handleChange                = this.handleChange.bind(this);
    this.handleFiltersChange         = this.handleFiltersChange.bind(this);
    this.handleSave                  = this.handleSave.bind(this);
  }

  render() {
    const { opened,
            index,
            functionType: reportFunctionType,
            selectedFields,
            onClose,
            ...forwardProps } = this.props;

    const { label,
            functionType,
            agg,
            component,
            windowComponent,
            isMainWindow,
            filters,
            fieldType,
            formatter } = this.state;

    const record           = selectedFields[index];
    const isAggFunction    = functionType === aggFunctionType;
    const isWindowFunction = functionType === windowFunctionType;
    const defaultLabel     = defaultColumnLabel(functionType, agg, record.field.name);
    const filteredWindowFunctionOptions = windowFunctionOptions.filter( ({ operator }) =>  operator === !isMainWindow );

    const showWindowOptions = shouldShowWindowOptions(isWindowFunction, isMainWindow, fieldType);

    return (
        <Modal open={ opened } onClose={ onClose } dimmer='inverted' closeIcon>
          <Header icon="cog" content="Edit Column" />
          <Modal.Content>
            <Form>
              <p>Apply changes to field:</p>
              <p className={ 'fieldNameStyle' }>{ record.field.name }</p>
              <Form.Input label="Column Label"
                          name='label'
                          value={ label || '' }
                          placeholder={ defaultLabel }
                          onChange={ this.handleChange } />

              { fieldType == 'address' &&
              <Form.Select  label='Select Address component'
                            name='component'
                            value={component}
                            options={ componentOptions }
                            clearable
                            onChange={ this.handleChange }
                            />
              }

              <Formatter  fieldId={ record.field.id }
                          fieldType={ fieldType }
                          isAggFunction={ isAggFunction }
                          formatter={ formatter }
                          onChange={ this.handleChange } />

              { fieldType != 'address' && <>
              <label className={ 'labelStyle' }>Apply function</label>
              <Form.Group inline>
              { functionOptions.map( ({text, value}, index) =>
                  <Form.Radio key={index}
                              name='functionType'
                              label={text}
                              value={value}
                              checked={functionType === value}
                              disabled={isFunctionTypeDisabled(value, reportFunctionType)}
                              onChange={this.handleChange}
                  />
                )
              }
              </Form.Group>
              </> }

              { isAggFunction &&
                <Form.Select  label='Select Aggregate function'
                              name='agg'
                              value={agg}
                              options={ aggregationOptions }
                              onChange={ this.handleChange }
                              />
              }

              { isWindowFunction && showWindowOptions &&
                <Form.Select  label='Select Window function'
                              name='windowComponent'
                              value={windowComponent}
                              options={ filteredWindowFunctionOptions }
                              onChange={ this.handleChange }
                              />
              }

              { (isAggFunction) &&
                <FilterSelector filters={ filters }
                                aggType= { true }
                                { ...forwardProps }
                                onChange={this.handleFiltersChange} />
              }

            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={ onClose } negative basic>Cancel</Button>
            <Button onClick={ this.handleSave } primary >Save</Button>
          </Modal.Actions>
        </Modal>
      );
  }

  handleChange(evt, {name, value}) {
    this.setState({
      [name]: value
    });
  }

  handleFiltersChange({ filters }) {
    this.setState({ filters });
  }

  handleSave() {
    const { index,
            reportDefinition,
            selectedFields,
            functionType: reportFunctionType,
            onChange }  = this.props;

    const { label, functionType, agg, component, agg_query, windowComponent, filters, formatter, hidden } = this.state;

    const isAggFunction    = (functionType === aggFunctionType && agg);

    const nextField = {
      ...selectedFields[index],
      label:      label || undefined,
      agg:        isAggFunction ? agg : undefined,
      component:  component ? component : undefined,
      agg_query:  agg_query ? agg_query : undefined,
      filters:    (isAggFunction && filters) ? filters.agg : undefined,
      formatter:  formatter || undefined,
      hidden:     hidden || undefined
    };

    selectedFields[index] = nextField;

    const nextWindow  = {
        ...reportDefinition.window,
    };

    if (windowComponent) {
      const formId = nextField.form;
      const [component, windowType] = windowComponent.split(':');

      let nextComponent = nextWindow[component] || {};
      let nextComponentField = {};
      if (functionType === windowFunctionType) {
        nextComponentField = createWindowComponentField(formId, nextField.field, nextField.type, windowType);
        if (component === 'compare_operations') {
          nextComponent[formId] = nextComponent[formId] || [];
          nextComponent[formId].push(nextComponentField);
        } else {
          nextComponent[formId] = nextComponentField;
        }
      } else { // user removed window function
        delete nextComponent[formId];
      }

      if (windowType === 'compare') { // remove order_field from every other form
        Object.keys(nextComponent).forEach( (form) => {
          const id = parseInt(form);
          if (formId !== id){
            delete nextComponent[id];
          }
        });

        nextWindow['comparison'] = true;

      } else if (component === 'compare_operations') {
        nextWindow['comparison'] = true;

      } else {
        nextWindow['comparison'] = false;
      }

      nextWindow[component] = nextComponent;

    }

    onChange({
      setFunctionType: (!reportFunctionType && functionType !== 'none') ? functionType : undefined,
      selectedFields: flattenSelectedFields(selectedFields),
      windowFunction: nextWindow
    });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

EditModal.propTypes = {
  opened:           PropTypes.bool.isRequired,
  index:            PropTypes.number.isRequired,
  functionType:     PropTypes.string,
  selectedFields:   PropTypes.array,
  reportDefinition: PropTypes.object,
  fields:           PropTypes.array,
  formCalculations: PropTypes.object,
  forms:            PropTypes.array,
  users:            PropTypes.array,
  onChange:         PropTypes.func.isRequired,
  onClose:          PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default EditModal;
