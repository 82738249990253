import React, { useState }       from 'react';
import PropTypes   from 'prop-types';
import { Button, Dropdown, Confirm }  from 'semantic-ui-react';

import OrganizationUserResource            from 'resources/admin/OrganizationUserResource';
import useOrganizationFetcher     from 'hooks/useOrganizationFetcher';


// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

async function unarchiveHandler({ userId, organizationId, unarchive }) {
  const params  = { organization_id: organizationId, id: userId };

  const response = await unarchive(params, {});

  if(response.id) {
    window.location.reload(true);
  }
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const UnarchiveButton = ({ userId, orgOptions }) => {
  const [ confirmOpen, setConfirmOpen ] = useState(false);
  const [ organizationId, setOrganizationId ] = useState(null);

  const unarchive         = useOrganizationFetcher(OrganizationUserResource.updateShape());
  const handleUnarchive   = (e, { value }) => {
    setConfirmOpen(true);
    setOrganizationId(value);
  };

  const handleCancelUnarchive = () => {
    setConfirmOpen(false);
    setOrganizationId(null);
  };

  const handleConfirmUnarchive = () => {
    setConfirmOpen(false);
    unarchiveHandler({ userId, organizationId, unarchive });
  };

  if(orgOptions.length === 1) {
    const [ option ]  = orgOptions;
    const { value }   = option;
    return (
      <>
        <Confirm open={ confirmOpen }
                  onCancel={ handleCancelUnarchive }
                  onConfirm={ handleConfirmUnarchive }
                  content={ 'Are you sure you want to unarchive this user?' }
                  confirmButton={ 'Unarchive' }
                  cancelButton={ 'Cancel' } />
        <Button floated={ 'right' }
                style={ { marginRight: '.25em' } }
                value={ value }
                onClick={ handleUnarchive }>Unarchive</Button>
      </>
    );
  } else if(orgOptions.length > 1) {
    return (
      <>
        <Confirm open={ confirmOpen }
                  onCancel={ handleCancelUnarchive }
                  onConfirm={ handleConfirmUnarchive }
                  content={ 'Are dsdsdyou sure you want to unarchive this user?' }
                  confirmButton={ 'Unarchive' }
                  cancelButton={ 'Cancel' } />
        <Dropdown style={{ float: 'right' }} text='Unarchive' button>
          <Dropdown.Menu direction='left'>
            {
              orgOptions.map(({ value, text }) => (
                <Dropdown.Item  key={ value }
                                value={ value }
                                onClick={ handleUnarchive }>

                  { text }
                </Dropdown.Item>
              ))
            }
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  } else {
    return null;
  }

};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

UnarchiveButton.propTypes = {
  userId: PropTypes.number,
  orgOptions: PropTypes.array
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default UnarchiveButton;
