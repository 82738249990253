import { systemFields } from 'constants/reports/SystemFields';
import indexOnAttribute from 'utils/indexOnAttribute';
import { isEqual, omit }     from 'lodash';

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export const refPrefix = 'ref_';
export const defaultFilters = { and: [] };


export function hasAggs(reportDefinition) {
  const { fields=[] } = reportDefinition || {};
  return fields.some(field => field.agg !== undefined);
}

export function isHouseholdReport(reportDefinition) {
  const { fields=[] } = reportDefinition || {};
  return fields.some(field => field.field === 'household_id');
}

export function recordRef(reportDefinition, isHouseholdReport, headers=[], record, index) {
  if (isHouseholdReport) {
    return record[0];
  }

  const { fields=[] } = reportDefinition || {};

  const columnOffset    = headers.length - fields.length;

  const field           = fields[index - columnOffset] || {};

  const formId          = field.form;
  const refColumnHeader = [refPrefix, formId].join('');
  const refIndex        = headers.indexOf(refColumnHeader);

  return record[refIndex];
}

// Extracts a list of unique form ids from the report definition
export function reportFormIds(reportDefinition) {
  const { fields=[] } = reportDefinition || {};
  const formIds       = fields.map(({ form }) => form).filter(form => form);
  const uniqueFormIds = new Set(formIds);
  return [ ...uniqueFormIds ];
}

// Creates a deep index of form calculations as:
// { formId => { calculationId => calculation } }
export function indexFormCalculations(formCalculations) {
  return  Object.entries(formCalculations)
                .filter(([formId]) => formId)
                .reduce((result, [formId, calculations]) => {
                  result[formId] = indexOnAttribute(calculations, 'id');
                  return result;
                }, {});
}

// Returns a mapping where keys are form ids and values are heterogenous arrays
// of fields and calculations.
export function formFieldObjectsMapping(forms, fields, formCalculations, reportDefinition) {

  if(forms.length === 0 || fields.length === 0) {
    return [];
  }

  const { fields: reportFields }  = reportDefinition;
  const typeSystemFields          = systemFields(reportDefinition, forms);
  const indexedFields             = indexOnAttribute(fields, 'id');
  const indexedSystemFields       = indexOnAttribute(typeSystemFields, 'id');

  // formCalculations is a map of form ids to an array of calculation records.
  // We need to index each calculation array in the map for efficient access.
  const indexedFormCalculations = indexFormCalculations(formCalculations);

  const mapping =
    reportFields.reduce((result, { form, field, type }) => {
                  const entry = type === 'calculation'
                                ? indexedFormCalculations[form]
                                  && indexedFormCalculations[form][field]
                                : indexedFields[field]
                                  || indexedSystemFields[field];

                  result[form] = result[form] || [];
                  entry && result[form].push(entry);

                  return result;
                }, {});

  return Object.entries(mapping);
}

export function computeSelectedFields({ currentFormId=null, fields, reportDefinition, reportTargetDefifinition=null, formCalculations }) {
  const { fields: reportFields }  = reportDefinition;
  const typeSystemFields          = systemFields(reportTargetDefifinition || reportDefinition);
  const indexedFields             = indexOnAttribute(fields, 'id');
  const indexedSystemFields       = indexOnAttribute(typeSystemFields, 'id');
  const indexedFormCalculations   = indexFormCalculations(formCalculations);

  return  reportFields
          .map(({ agg, form, field, component, type, label, agg_query, filters, formatter, hidden }) => (
            {
              agg, form, component, type, label, agg_query, filters, formatter, hidden,
              field:  type === 'calculation'
                      ? indexedFormCalculations[form]
                        && indexedFormCalculations[form][field]
                      : indexedFields[field]
                        || indexedSystemFields[field]
            }
          ))
          .filter(({ form, field }) => field &&
                                      (!currentFormId || form === currentFormId)
                  );
}

export function flattenSelectedFields(fields) {
  return fields.map( ({ agg, form, field: { id }, component, type, label, agg_query, filters, formatter, hidden }) =>
            ({ agg, form, field: id, component, type, label, agg_query, filters, formatter, hidden })
          );
}

// Returns the WindowComponent if a window function is applyed to a given field
export function deriveWindowComponent(windowFunction, fieldId, formId, fieldType) {
  if(!windowFunction || !fieldId || !formId || !fieldType) {
    return null;
  }

  const field = { form: formId, field: fieldId, type: fieldType };
  const { order_fields, compare_operations } = windowFunction;

  // if(partition_field && partition_field[formId]){
  //   const pf = omit(partition_field[formId], 'window');

  //   if(isEqual(pf, field)) {
  //     return 'partition_field';
  //   }
  // }

  if(order_fields && order_fields[formId]){
    const of = omit(order_fields[formId], 'function');

    if(isEqual(of, field)) {
      const window_type = order_fields[formId]['function'];
      return `order_fields:${window_type}`;
    }
  }

  if(compare_operations && compare_operations[formId]){
    const result = [...compare_operations[formId]].reverse().find(cf => {
      const cff = omit(cf, 'function');
      return isEqual(cff, field);
    });

    if (result) {
      const window_type = result['function'];
      return `compare_operations:${window_type}`;
    }
  }

  return null;
}

// Returns the filters for a field either if it's a window function
// or an aggregate function
export function deriveFilters(formId, isAggFunction, filters, windowFunction) {
  if (isAggFunction && filters) {
    return { agg: filters };
  }

  if (windowFunction && windowFunction.filters) {
    return windowFunction.filters[formId];
  }

  return null;
}

const formFieldIdDelimiter = '_';

export function deriveFieldSlug(type, fieldId, formId) {
  const idComponents = [type, fieldId];

  if(formId) {
    idComponents.push(formId);
  }

  return idComponents.join(formFieldIdDelimiter);
}
