import React, { Component }           from 'react';
import PropTypes                      from 'prop-types';
import { Table, Dropdown, Icon }      from 'semantic-ui-react';

import { windowFunctionType,
         aggFunctionType,
         aggregationOptions,
         windowFunctionOptions }      from 'constants/reports/FunctionTypes';
import { flattenSelectedFields,
         deriveWindowComponent }      from 'utils/reportDefinitions';
import { find }                       from 'lodash';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const recordOptions = [
  { value: 'edit_field',       text: 'Edit Label/Function...' },
  { value: 'hide_field',       text: 'Hide Field' },
  { value: 'duplicate_field',  text: 'Duplicate Field' },
  { value: 'remove_field',     text: 'Remove Field' }
];

const trigger = (
  <span><Icon name='ellipsis horizontal' /></span>
);

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class Record extends Component {
  constructor(props) {
    super(props);

    this.handleSelectAction = this.handleSelectAction.bind(this);
    this.handleDuplicate    = this.handleDuplicate.bind(this);
    this.handleRemove       = this.handleRemove.bind(this);
  }

  render() {
    const { index,
            selectedFields,
            functionType,
            windowFunction } = this.props;

    const record            = selectedFields[index];
    const isAggFunction     = functionType === aggFunctionType;
    const isWindowFunction  = functionType === windowFunctionType;

    const windowComponent   = isWindowFunction
                              ? deriveWindowComponent( windowFunction, record.field.id, record.form, record.type)
                              : null;

    const selectedFunction  = (isAggFunction && record.agg)
                              ? find(aggregationOptions,['value', record.agg]).text
                              : (isWindowFunction && windowComponent)
                                ? find(windowFunctionOptions, ['value', windowComponent]).text
                                : null;

    return (
      <Table.Row key={ index }>
        <Table.Cell className={ record.hidden ? 'hidden-column' : '' }>
            { record.hidden && <Icon name='eye slash outline' /> }
            { record.label || record.field.name }
        </Table.Cell>
        <Table.Cell>
          { selectedFunction }
        </Table.Cell>
        <Table.Cell textAlign="right">
            <Dropdown name={ record.id }
                      trigger={ trigger }
                      icon={ null }
                      options={ recordOptions }
                      selectOnBlur={ false }
                      value={ null }
                      onChange={ this.handleSelectAction } />
        </Table.Cell>
      </Table.Row>
    );
  }

  handleHideField() {
    const { index, onChange, selectedFields, windowFunction } = this.props;

    const field = selectedFields[index];
    const formId = field.form;
    field.hidden = !field.hidden;

    onChange({
      currentFormId: formId,
      windowFunction,
      selectedFields: flattenSelectedFields(selectedFields)
    });
  }

  handleDuplicate() {
    const { index,
            onChange,
            selectedFields,
            windowFunction }  = this.props;

    const formId = selectedFields[index].form;
    const nextFields = [
      ...selectedFields,
      { ...selectedFields[index] }
    ];

    onChange({
      currentFormId: formId,
      windowFunction,
      selectedFields: flattenSelectedFields(nextFields)
    });
  }

  handleRemove() {
    const { index,
            onChange,
            selectedFields,
            windowFunction }  = this.props;

    const formId = selectedFields[index].form;
    selectedFields.splice(index, 1);

    onChange({
      currentFormId: formId,
      windowFunction,
      selectedFields: flattenSelectedFields(selectedFields)
    });
  }

  handleSelectAction(evt, { value }) {
    const { index,
            onEditFunction } = this.props;

    switch (value) {
      case 'edit_field':
        onEditFunction({currentFieldIndex: index});
        break;
      case 'hide_field':
        this.handleHideField();
        break;
      case 'duplicate_field':
        this.handleDuplicate();
        break;
      case 'remove_field':
        this.handleRemove();
        break;

      // no default
    }

  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Record.propTypes = {
  functionType: PropTypes.string,
  selectedFields: PropTypes.array,

  windowFunction: PropTypes.shape({
    type: PropTypes.array,
    partition_field: PropTypes.shape({
      form:   PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      field:  PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    order_field: PropTypes.shape({
      form:   PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      field:  PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }),

  onEditFunction: PropTypes.func.isRequired,
  onDuplicateField: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Record;
