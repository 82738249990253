import React, { Fragment }      from 'react';
import { Header, Segment }      from 'semantic-ui-react';
import { Redirect }             from 'react-router-dom';

import DataList                 from 'components/DataList';
import * as actions             from 'constants/ActionTypes';
import connectResource          from 'utils/connectResource';
import { getById, getByIds }      from 'utils/connectors';
import cast                     from 'utils/cast';
import formattedDate            from 'utils/formattedDate';
import { users, organizations } from 'resources/adminResources';
import impersonation            from 'resources/impersonation';
import OrganizationList         from './OrganizationList';
import ImpersonationButton      from './ImpersonationButton';
import ArchiveButton            from './ArchiveButton';
import UnarchiveButton          from './UnarchiveButton';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

function getUserDetails({ email,
                          role,
                          created_at,
                          updated_at,
                          last_logged_in_at,
                          last_accessed_at,
                          last_login_ip,
                          time_zone }) {

  const emailLink = (
    <a href={`mailto:${email}`} target='blank'>{email}</a>
  );

  return [
    { label: 'Email',                 value: emailLink },
    { label: 'Role',                  value: role },
    { label: 'Time Zone',             value: time_zone },
    { label: 'Created At',            value: formattedDate(created_at) },
    { label: 'Updated At',            value: formattedDate(updated_at) },
    { label: 'Last Accessed At',      value: formattedDate(last_accessed_at) },
    { label: 'Last Logged-in At',     value: formattedDate(last_logged_in_at) },
    { label: 'Last Login IP Address', value: last_login_ip }
  ];
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const DetailView = ({ data, loading, api_errors, create, history, setOrgdomain }) => {
  const user          = data.user             || {};
  const organizations = data.organizations    || {};
  const orgIds        = user.organization_ids || [];
  const activeOrgIds  = user.active_organization_ids || [];
  const archivedOrgIds  = user.archived_organization_ids || [];

  const { first_name, last_name, role, archived } = user;
  const dataListItems                   = getUserDetails(user);

  const orgOptions  = activeOrgIds.map((id) => ({
    text:   (organizations[id] || {}).name,
    value:  cast(id, 'string')
  }));

  const orgOptionsToUnarchive  = archivedOrgIds.map((id) => ({
    text:   (organizations[id] || {}).name,
    value:  cast(id, 'string')
  }));

  return (
    <Fragment>
      <Segment loading={ loading }>
        {
          api_errors && <Redirect to='./'/>
        }

        <Header as='h2'>
          { first_name } { last_name }
          <ImpersonationButton  orgOptions={ orgOptions }
                                user={ user }
                                organizations={ organizations }
                                create={ create }
                                setOrgDomain={ setOrgdomain }
                                onSuccess={ () => history.push('/') } />
          { !archived &&
            <ArchiveButton userId={ user.id }
                           orgOptions={ orgOptions } />}
          { archivedOrgIds.length > 0 &&
            <UnarchiveButton userId={ user.id } orgOptions={ orgOptionsToUnarchive } />}
        </Header>
        <DataList items={ dataListItems } />
      </Segment>

      {
        role !== 'superuser' &&
        <OrganizationList loading={ loading }
                          organizations={ Object.values(organizations) }
                          userOrganizationIds={ orgIds }
                          userActiveOrganizationIds={ activeOrgIds } />
      }
    </Fragment>
  );
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------


export default connectResource(DetailView, {
  connectors: [
    {
      user: getById(users, ({ match: { params: { id } } }) => id)
    },
    {
      organizations: getByIds(organizations, ({ data }) => {
        if (data.user && data.user.id) {
          return data.user.organization_ids;
        }

        return [];
      })
    }
  ],
  key: (props) => props.match.params.id,
  mapDispatchToProps: {
    ...impersonation.actions,
    setOrgdomain: (domain) => ({
      type:     actions.SET_ORGDOMAIN,
      payload:  domain
    })
  }
});
