import React, { Component }  from 'react';
import { Form }              from 'semantic-ui-react';
import formattedDate         from 'utils/formattedDate';

import 'style/containers/organization/reports/wizard.css';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------
const today = new Date();

const dateTypeOptions = [
      { value: 'date',         text: formattedDate(today, 'date') },
      { value: 'short_date',   text: formattedDate(today, 'short_date') },
      { value: 'month_year',   text: formattedDate(today, 'month_year') },
      { value: 'alpha_date',   text: formattedDate(today, 'alpha_date') },
      { value: 'alpha_month_year', text: formattedDate(today, 'alpha_month_year') },
      { value: 'quarter',      text: formattedDate(today, 'quarter') },
      { value: 'dow',          text: formattedDate(today, 'dow') },
      { value: 'year',         text: formattedDate(today, 'year') },
      { value: 'time',         text: formattedDate(today, 'time') },
      { value: 'hour',         text: formattedDate(today, 'hour') }
];

const timeFormatOptions = [
  { value: '24-hour',    text: '24h-hour clock' },
  { value: '12-hour',  text: '12h-hour clock (AM/PM)' },
];
const numericTypeOptions = [
      { value: 'integer',  text: 'Integer' },
      { value: 'float',    text: 'Float' },
      { value: 'currency', text: 'Currency' }
];

export const timeTypeOptions = [
  { value: 'float',    text: 'Hours' },
  { value: 'minutes',  text: 'Minutes' },
  { value: 'time',  text: 'hh:mm' },
  { value: 'time_decimal',  text: 'h.mm' },
];

const fieldTypeFormatOptions = {
  'date':         dateTypeOptions,
  'number':       numericTypeOptions,
  'numeric':      numericTypeOptions,
  'time':         timeFormatOptions,
  'timestamp':    dateTypeOptions,
  'time_from':    dateTypeOptions,
  'time_since':   timeTypeOptions,
  'time_between': timeTypeOptions,
};

const currencyOptions = [
  { value: '$',    text: 'USD ($)' },
  { value: '€',    text: 'EUR (€)' }
];

const timestampOptions = [
  { value: 1,    text: 'Yes' },
  { value: 0,    text: 'No' }
];

const outputOptions = [
  { value: 'value',    text: 'Value' },
  { value: 'label',    text: 'Label' }
];

const timestampedFields = ['created_at', 'updated_at', 'completed_at', 'client_created_at', 'client_updated_at'];
const selectionFields   = ['select-one', 'select-many'];
const timeFields        = ['time_since', 'time_between'];

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class Formatter extends Component {
  constructor(props) {
    super(props);

    this.handleChange    = this.handleChange.bind(this);
  }

  render() {
    const { fieldId,
            fieldType,
            isAggFunction,
            formatter }        = this.props;

    const { format,
            precision,
            currency,
            timestamp=true,
            output='label' }   = formatter || {};

    const showOutputOptions    = selectionFields.includes(fieldType);
    const hasFormatter = isAggFunction || showOutputOptions || Object.keys(fieldTypeFormatOptions).includes(fieldType);
    if (!hasFormatter) { return null; }

    const formatType           = isAggFunction && !timeFields.includes(fieldType) ? 'number' : fieldType;
    const formatOptions        = fieldTypeFormatOptions[formatType];
    const showFormatOptions    = isAggFunction || !showOutputOptions;
    const showTimestampOptions = !isAggFunction &&
                                  (fieldType === 'timestamp' ||
                                  timestampedFields.includes(fieldId));
    const showTimestamp        = timestamp ? 1 : 0;

    return (
      <div>
        { showFormatOptions &&
        <Form.Select  label='Output Format'
                      name='format'
                      value={ format }
                      options={ formatOptions }
                      onChange={ this.handleChange } />
        }

        { format === 'float' &&
        <Form.Input label='Precision'
                    name='precision'
                    value={ precision }
                    type='number'
                    onChange={ this.handleChange } />
        }

        { format === 'currency' &&
        <Form.Select  label='Currency'
                      name='currency'
                      value={ currency }
                      options={ currencyOptions }
                      onChange={ this.handleChange } />
        }
        { showTimestampOptions &&
        <Form.Group inline>
        <label className={ 'labelStyle' }>Show timestamp: </label>
        { timestampOptions.map( ({text, value}, index) =>
          <Form.Radio key={index}
                      name='timestamp'
                      label={text}
                      value={value}
                      checked={showTimestamp === value}
                      onChange={this.handleChange} />
        )}
        </Form.Group>
        }
        { showOutputOptions &&
        <Form.Group inline>
        <label className={ 'labelStyle' }>Display as: </label>
        { outputOptions.map( ({text, value}, index) =>
          <Form.Radio key={index}
                      name='output'
                      label={text}
                      value={value}
                      checked={output === value}
                      onChange={this.handleChange} />
        )}
        </Form.Group>
        }
      </div>
    );
  }

  handleChange(evt, {name, value}) {
    const { formatter, onChange } = this.props;

    let newValue = value;
    if (name === 'precision') { newValue = +value; }
    else if (name === 'timestamp') { newValue = !!value; }

    const newFormatter = {
      ...formatter,
      [name]: (value !== '') ? newValue : undefined
    };

    onChange(evt, {
        name:  'formatter',
        value: newFormatter
      }
    );
  }
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Formatter;
