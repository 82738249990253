import React, { Component }                       from 'react';
import PropTypes                                  from 'prop-types';
import { List, Ref, Dropdown, Icon }              from 'semantic-ui-react';
import { DragDropContext, Droppable, Draggable }  from 'react-beautiful-dnd';
import { compact }                                from 'lodash';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const defaultOrderName  = 'default_order';
const maxSortColumns    = 3;

const draggingStyle = {
  userSelect: 'none',
  background: 'lightyellow'
};

const baseStyle = {
  transition: 'background 0.75s'
};

const sortOptions = [
  { text: 'Sort Ascending',   value: 'asc' },
  { text: 'Sort Descending',  value: 'desc' }
];

function getStyle({ isDragging },
                  { draggableProps: { style: draggableStyle } }) {

  const style = { ...draggableStyle, ...baseStyle };

  if(isDragging) {
    return { ...style, ...draggingStyle,  };
  }

  return style;
}

function asSortField({ agg, form, type, field: { id } }) {
  return { agg, form, type, field: id };
}

function orderValues(fields, defaultOrder) {
  if(defaultOrder === null || defaultOrder === undefined) {
    return {};
  }

  return fields.reduce((values, { form, type, field: { id } }, i) => {
    const orderField  = defaultOrder.find(({ form: oForm, type: oType, field: oField }) => (
                          form === oForm
                          && id === oField
                          && type === oType
                        ));

    if(orderField) {
      values[i] = orderField.order;
    }

    return values;
  }, {});
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class ColumnOrderList extends Component {
  constructor(props) {
    super(props);

    this.handleDragEnd    = this.handleDragEnd.bind(this);
    this.handleSortSelect = this.handleSortSelect.bind(this);
  }

  render() {
    const { fields, defaultOrder }  = this.props;
    const values                    = orderValues(fields, defaultOrder);

    return (
      <DragDropContext onDragEnd={ this.handleDragEnd }>
        <Droppable droppableId='report-column-list'>
          {
            (provided) => (
              <Ref innerRef={ provided.innerRef }>
                <List celled verticalAlign='middle'>
                  {
                    fields.map(({agg, label, form, field: { id, name }, hidden }, i) => (
                      <Draggable  key={ `${i}_${id}_${form}` }
                                  draggableId={ `${i}_${id}_${form}` }
                                  index={ i }>

                        {
                          (provided, snapshot) => (
                            <Ref innerRef={ provided.innerRef }>
                              <List.Item  { ...provided.draggableProps }
                                          style={ getStyle(snapshot, provided) }>

                                <List.Content floated='right'>
                                  <Dropdown inline
                                            clearable
                                            name={ i }
                                            icon="ellipsis horizontal"
                                            selectOnBlur={ false }
                                            value={ values[i] }
                                            options={ sortOptions }
                                            onChange={ this.handleSortSelect } />
                                </List.Content>
                                <List.Icon  name='bars'
                                            size='large'
                                            { ...provided.dragHandleProps } />

                                <List.Content>
                                  <List.Header className={ hidden ? 'hidden-column' : '' }>
                                    { hidden && <Icon name='eye slash outline' /> }
                                    { label || compact([agg, name]).join(' ') }
                                   </List.Header>
                                </List.Content>
                              </List.Item>
                            </Ref>
                          )
                        }
                      </Draggable>
                    ))
                  }
                </List>
              </Ref>
            )
          }
        </Droppable>
      </DragDropContext>
    );
  }

  handleDragEnd(evt) {
    const { source, destination } = evt;

    if(source === null || destination === null) {
      return;
    }

    const { name, fields, onChange }  = this.props;
    const { index: start }            = source;
    const { index: end }              = destination;

    const reportFields  = fields.map(({ field: { id }, ...fieldProps }) => (
                            { field: id, ...fieldProps }
                          ));

    const draggedField  = reportFields[start];

    reportFields.splice(start, 1);
    reportFields.splice(end, 0, draggedField);

    onChange(evt, { name, value: reportFields });
  }

  handleSortSelect(evt, { name, value }) {
    const { defaultOrder, fields, onChange }  = this.props;
    const fieldIndex                          = +name;
    const field                               = fields[fieldIndex];
    const sortField                           = asSortField(field);
    const [ ...nextDefaultOrder ]             = defaultOrder || [];

    // See if the field already has a sort directive in the queue
    const queueIndex = nextDefaultOrder.findIndex(({ form, field, type }) => (
      sortField.form === form
      && sortField.field === field
      && sortField.type === type
    ));

    // if the field already has an entry, remove the existing entry.
    if(queueIndex > -1) {
      nextDefaultOrder.splice(queueIndex, 1);
    }

    // if the value is 'asc' or 'desc', push it onto the queue.
    if(value) {
      nextDefaultOrder.push({ ...sortField, order: value });
    }

    // if the queue has no length, value is null.
    if(nextDefaultOrder.length === 0) {
      onChange(evt, { name: defaultOrderName, value: null });

    // if the queue has length, value is the last `maxSortColumns` elements of
    // the queue.
    } else {
      onChange(evt, {
        name: defaultOrderName,
        value: nextDefaultOrder.slice(-maxSortColumns)
      });
    }
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ColumnOrderList.propTypes = {
  name:         PropTypes.string.isRequired,
  fields:       PropTypes.arrayOf(PropTypes.shape({
                  form:   PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
                  field:  PropTypes.object,
                  agg:    PropTypes.string,
                  label:  PropTypes.string
                })).isRequired,
  onChange:     PropTypes.func.isRequired,
  defaultOrder: PropTypes.array,
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ColumnOrderList;
